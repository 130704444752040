import React from 'react';
import logoimg from '../assets/somoAI.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  const year = new Date().getFullYear(); // Get current year

  return (
    <section className="relative">
      <footer className="fixed bottom-0 w-full bg-gray-50 shadow-md py-2 px-4 sm:px-3 lg:px-4">
        <div className="flex justify-between items-center text-gray-500">
          <Link to="/"> {/* Link logo to "/" using Link */}
            <img
              src={logoimg}
              className="w-auto h-5" // Set logo height
              alt="Logo"
            />
          </Link>
          <p className="text-xs">Copyright &copy; {year}. Konza Technopolis. All rights reserved.</p>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
