import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { XMarkIcon, HomeIcon } from '@heroicons/react/24/outline';
import { FaUserAlt, FaSignInAlt } from 'react-icons/fa';

function Navigation() {
    const [nav, setNav] = useState(false);
    
    const handleClick = () => setNav(!nav);

    const handleClose = () => {
        setNav(false);
    };

    return (
        <div className='w-screen bg-gray-100 h-[70px] z-10 fixed drop-shadow-lg'>
            <div className='px-2 flex justify-between items-center w-full h-full'>
                <div className='flex items-center'>
                    <h2 className='text-3xl font-bold text-black mr-4 sm:text-4xl'>Lano Tech.</h2>
                    <ul className='hidden text-black md:flex'>
                        <li>
                            <Link to="/" className='cursor-pointer hover:bg-white hover:text-green-600 hover:rounded-lg'>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/about" className='cursor-pointer relative group hover:bg-white hover:text-green-600 hover:rounded-lg'>
                                Lano Tech
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact" className='cursor-pointer relative group hover:bg-white hover:text-green-600 hover:rounded-lg'>
                                Contact Us
                            </Link>
                        </li>
                        <li>
                            <Link to="/services" className='cursor-pointer relative group hover:bg-white hover:text-green-600 hover:rounded-lg'>
                                Services
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className='hidden md:flex pr-4'>
                    <Link to="/login" className="flex text-center cursor-pointer items-center mx-4 text-black hover:text-green-600">
                        <FaSignInAlt className='lg:w-5 lg:h-5 mx-2' />
                        <span className="text-sm font-medium">
                            Login
                        </span>
                    </Link>
                    <Link to="/account" className="block cursor-pointer shrink-0 rounded-lg bg-white p-2.5 border border-gray-100 shadow-sm hover:bg-transparent hover:text-green-600 hover:border hover:border-green-600">
                        <span className="sr-only">Account</span>
                        <FaUserAlt className='lg:w-5 lg:h-5' />
                    </Link>
                </div>
                <div className='md:hidden mr-4' onClick={handleClick}>
                    {!nav ? <HomeIcon className='w-5 text-black' /> : 
                    <div className='flex'>
                        <Link to="/login" className="flex text-center cursor-pointer items-center mx-4 text-black hover:text-green-600">
                            <FaSignInAlt className='lg:w-5 lg:h-5 mx-2' />
                            <span className="text-sm font-medium">
                                Login
                            </span>
                        </Link>
                        <Link to="/account" className="block cursor-pointer shrink-0 rounded-lg bg-white mr-4 p-2.5 border border-gray-100 shadow-sm hover:bg-transparent hover:text-green-600 hover:border hover:border-green-600">
                            <span className="sr-only">Account</span>
                            <FaUserAlt className='lg:w-5 lg:h-5' />
                        </Link>
                        <XMarkIcon className='w-5 text-black' /> 
                    </div>}
                </div>
            </div>
            <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8'}>
                <li onClick={handleClose} className='border-b-2 border-zinc-300 w-full'>
                    <Link to="/" className='hover:bg-white hover:text-green-600 hover:rounded-lg'>Home</Link>
                </li>
                <li onClick={handleClose} className='border-b-2 border-zinc-300 w-full'>
                    <Link to="/about" className='hover:bg-white hover:text-green-600 hover:rounded-lg'>About Us</Link>
                </li>
                <li onClick={handleClose} className='border-b-2 border-zinc-300 w-full'>
                    <Link to="/contact" className='hover:bg-white hover:text-green-600 hover:rounded-lg'>Contact Us</Link>
                </li>
                <li onClick={handleClose} className='border-b-2 border-zinc-300 w-full'>
                    <Link to="/services" className='hover:bg-white hover:text-green-600 hover:rounded-lg'>Services</Link>
                </li>
            </ul>
        </div>
    );
}

export default Navigation;
