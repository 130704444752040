import React from 'react';

const Subscription = ({ packages }) => {
  return (
    <section className="relative bg-white">
      <section className="bg-gray-100 py-8">
        <div className="container mx-auto px-4">
          <h1 className="lg:text-5xl text-2xl font-bold">Subscription</h1>
        </div>
        <main>
          <div className="container mx-auto mt-8 px-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              {packages.length > 0 && packages[0].map((pkg, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out"
                >
                  <h3 className="text-2xl font-semibold mb-4">{pkg.title}</h3>
                  <p className="text-gray-500 mb-4">{pkg.description}</p>
                  <div className="mb-4">
                    <span className="text-3xl font-bold">{pkg.price}</span>
                    <span className="text-gray-500 ml-2">/ month</span>
                  </div>
                  <ul className="list-disc pl-6">
                    {pkg.features.map((feature, index) => (
                      <li key={index} className="text-gray-700 mb-2">
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <button
                  className="bg-green-600 cursor-pointer hover:bg-black text-white font-semibold px-4 py-2 mt-4 rounded-lg"
                  onClick={() => {
                    // Handle subscription button click
                }}>
                    Subscribe
                    </button>
                </div>
              ))}
            </div>
          </div>
        </main>
      </section>
    </section>
  );
};

export default Subscription;
