import React from 'react';

import airpotimg from '../assets/airport.jpeg';
import { MdContactSupport } from 'react-icons/md'; // Import the necessary icon component

const ContactSection = () => {
  return (
    <section className="relative pb-2">
      {/* Background Image */}
      <div className='w-full h-[700px] bg-gray-900/90 absolute'>
        {/* Assuming airpotimg is defined somewhere */}
        <img className='w-full h-full object-cover mix-blend-overlay' src={airpotimg} alt="Background" />
      </div>

      {/* Contact Form */}
      <div className='max-w-[1240px] mx-auto text-white relative'>
        <div className="relative overflow-hidden mx-4 -top-10 bg-white rounded-lg shadow-md">
          <div className="flex px-4 pt-8">
            <fieldset className="grid grid-cols-2 gap-4">
              <legend className="sr-only">Service</legend>
              {/* Radio buttons for service options */}
              <div>
                <input
                  type="radio"
                  name="ServiceOption"
                  value="Basic"
                  id="BasicService"
                  className="peer hidden [&:checked_+_label_svg]:block"
                  checked
                />
                <label
                  htmlFor="BasicService"
                  className="block cursor-pointer rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-green-600 peer-checked:ring-1 peer-checked:ring-green-600"
                >
                  <div className="flex items-center justify-between">
                    <p className="text-gray-700">Basic Service</p>
                  </div>
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="ServiceOption"
                  value="Standard"
                  id="StandardService"
                  className="peer hidden [&:checked_+_label_svg]:block"
                />
                <label
                  htmlFor="StandardService"
                  className="block cursor-pointer rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-green-600 peer-checked:ring-1 peer-checked:ring-green-600"
                >
                  <div className="flex items-center justify-between">
                    <p className="text-gray-700">Standard Service</p>
                  </div>
                </label>
              </div>
            </fieldset>
          </div>

          {/* Form Fields */}
          <div className="flex px-4 pt-1">
            <div className="grid mx-auto py-4 grid-cols-1 gap-y-8 lg:grid-cols-5 lg:items-center lg:gap-x-4">
              {/* Name input */}
              <label
                htmlFor="Name"
                className="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-green-600 focus-within:ring-1 focus-within:ring-green-600"
              >
                <input
                  type="text"
                  id="Name"
                  className="peer h-14 w-full text-black px-4 border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0"
                  placeholder="Your Name"
                />
                <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
                  Your Name
                </span>
              </label>
              {/* Email input */}
              <label
                htmlFor="Email"
                className="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-green-600 focus-within:ring-1 focus-within:ring-green-600"
              >
                <input
                  type="email"
                  id="Email"
                  className="peer h-14 w-full text-black px-4 border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0"
                  placeholder="Your Email"
                />
                <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
                  Your Email
                </span>
              </label>
              {/* Phone input */}
              <label
                htmlFor="Phone"
                className="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-green-600 focus-within:ring-1 focus-within:ring-green-600"
              >
                <input
                  type="tel"
                  id="Phone"
                  className="peer h-14 w-full text-black px-4 border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0"
                  placeholder="Your Phone Number"
                />
                <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
                  Your Phone Number
                </span>
              </label>
              {/* Message input */}
              <label
                htmlFor="Message"
                className="col-span-full relative block rounded-md border border-gray-200 shadow-sm focus-within:border-green-600 focus-within:ring-1 focus-within:ring-green-600"
              >
                <textarea
                  id="Message"
                  rows="3"
                  className="peer h-24 w-full text-black px-4 border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 resize-none"
                  placeholder="Your Message"
                ></textarea>
                <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
                  Write Your Message
                </span>
              </label>
            </div>
          </div>

          {/* Submit button */}
          <div className="flex py-4">
            <button
              type="submit"
              className="flex mx-auto text-center cursor-pointer items-center px-4 py-4 hover:text-white bg-green-700 rounded hover:bg-black active:text-white focus:outline-none focus:ring"
            >
              <MdContactSupport className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              <span className="text-sm font-medium">Enquire Now</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
