/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navigation  from "./components/Navigation";
import Footer  from "./components/Footer";
import ContactSection from './components/ContactForm'; 
import OurSolutionsSection from "./components/OurSolutions";
import Subscription from './components/Subscription';
import Login from './components/Login';
import Register from './components/Register';
import AboutUs from './components/AboutUs';
import Services from './components/Services';


import backgroundimg from './assets/umberto-FewHpO4VC9Y-unsplash.jpg';


const packages = [
  [
    {
      title: "Basic Package",
      description: "LANO TECH's custom software development service offers tailored solutions to meet your unique requirements.",
      price: "Kes9900",
      features: [
        "Custom Software Development",
        "Seamless Integration",
        "Optimal Performance"
      ]
    },
    {
      title: "Standard Package",
      description: "LANO TECH specializes in creating dynamic, user-friendly websites and web applications that engage audiences and drive results.",
      price: "Kes19900",
      features: [
        "Web Development",
        "Dynamic Websites",
        "User-Friendly Interfaces"
      ]
    },
    {
      title: "Premium Package",
      description: "LANO TECH builds innovative mobile applications for iOS and Android platforms, delivering intuitive user experiences and powerful functionality.",
      price: "Kes29900",
      features: [
        "Mobile App Development",
        "iOS and Android Platforms",
        "Intuitive User Experiences"
      ]
    },
    {
      title: "Customized Package",
      description: "LANO TECH builds innovative mobile applications for iOS and Android platforms, delivering intuitive user experiences and powerful functionality.",
      price: "Kes49900",
      features: [
        "Mobile App Development",
        "iOS and Android Platforms",
        "Intuitive User Experiences"
      ]
    }
  ]
  
];


function App() {
  return (
    <Router>
      <Navigation />
      <div className="relative">

        {/* Background image with high z-index */}
        <div
          className="fixed inset-0"
          style={{
            backgroundImage: `url(${backgroundimg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: -1  // Set a low z-index
          }}
        ></div>

        {/* Route setup */}
        <Routes>
          <Route path="/" element={
              <>
                <OurSolutionsSection />
                <ContactSection />
                <Subscription packages={packages} />
              </>
            } />
          <Route path="/login" element={<Login style={{ zIndex: 0 }} />} />  {/* Set z-index for Login */}
          <Route path="/signup" element={<Register style={{ zIndex: 0 }} />} />  {/* Set z-index for Login */}
          <Route path="/about" element={<AboutUs style={{ zIndex: 0 }} />} />  {/* Set z-index for Login */}
          <Route path="/services" element={<Services style={{ zIndex: 0 }} />} />  {/* Set z-index for Login */}
          <Route path="/contact" element={<ContactSection style={{ zIndex: 0 }} />} />  {/* Set z-index for Login */}


        </Routes>

        {/* Footer */}
        <Footer />

      </div>
    </Router>
  );
}

export default App;