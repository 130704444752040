import React from 'react';

const OurSolutionsSection = () => {
  return (
    <section className="relative mb-16 z-0 py-4">
      <div className="grid container mx-auto pt-40 p-6 grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
        <div className="mx-auto max-w-lg lg:mx-0 ltr:lg:text-left rtl:lg:text-right">
          <h2 className="text-3xl font-bold sm:text-4xl text-slate-300">Find Solutions</h2>
          <p className="mt-4 text-slate-300">
            Lano Tech is at the forefront of innovation as an AI-powered tech startup. Our mission is to revolutionize industries through cutting-edge artificial intelligence technologies. We are committed to leveraging AI to drive efficiency, enhance decision-making, and unlock new possibilities for our clients. Partner with us to harness the power of AI and stay ahead in today's rapidly evolving digital landscape.
          </p>
          {/* Input for search here */}
          <div className="relative mt-8">
            <label htmlFor="Search" className="sr-only">Search</label>
            <input
              type="text"
              id="Search"
              placeholder="Search solutions..."
              className="w-full h-12 focus:outline-none rounded-full border-none border-gray-200 pe-10 ps-4 text-sm py-2.5 shadow-sm sm:text-sm"
            />
            <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
              <button
                type="button"
                className="absolute border border-emerald-700 end-1 top-1/2 -translate-y-1/2 rounded-full bg-gray-50 p-2 text-gray-600 transition hover:text-gray-700"
              >
                <span className="sr-only">Search</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </button>
            </span>
          </div>
        </div>
        
        {/* Statistics here */}
        <div>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-2">
            {/* Solution */}
            <div className="flex flex-col rounded-lg border border-emerald-600 hover:bg-gray-200 hover:border-gray-200 px-4 py-8 text-center">
              <dt className="order-last text-lg font-medium text-slate-500">Solutions</dt>
              <dd className="text-4xl font-extrabold mb-4 text-white md:text-5xl">9+</dd>
            </div>
            {/* Happy Customers */}
            <div className="flex flex-col rounded-lg border border-emerald-600 hover:bg-gray-200 hover:border-gray-200 px-4 py-8 text-center">
              <dt className="order-last text-lg font-medium text-slate-500">Happy Customers</dt>
              <dd className="text-4xl font-extrabold mb-4 text-white md:text-5xl">20+</dd>
            </div>
            {/* Customer Satisfaction */}
            <div className="flex flex-col rounded-lg border border-emerald-600 hover:bg-gray-200 hover:border-gray-200 px-4 py-8 text-center">
              <dt className="order-last text-lg font-medium text-slate-500">Customer Satisfaction</dt>
              <dd className="text-4xl font-extrabold mb-4 text-white md:text-5xl">9+</dd>
            </div>
            {/* Market Impact */}
            <div className="flex flex-col rounded-lg border border-emerald-600 hover:bg-gray-200 hover:border-gray-200 px-4 py-8 text-center">
              <dt className="order-last text-lg font-medium text-slate-500">Market Impact</dt>
              <dd className="text-4xl font-extrabold mb-4 text-white md:text-5xl">1.8m</dd>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSolutionsSection;
