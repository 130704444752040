import React from 'react';

const Services = () => {
  return (
    <div className="container mx-auto py-8">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold mb-2">Our Services</h2>
        <p className="text-lg text-slate-300">Comprehensive Software Development Solutions</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-2">Custom Software Development</h3>
          <p className="text-slate-300">
            We design and develop bespoke software solutions tailored to the specific requirements of our clients, ensuring seamless integration and optimal performance.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-2">Web Development</h3>
          <p className="text-slate-300">
            Our team specializes in creating dynamic, user-friendly websites and web applications that engage audiences and drive results.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-2">Mobile App Development</h3>
          <p className="text-slate-3">
            We build innovative mobile applications for iOS and Android platforms, delivering intuitive user experiences and powerful functionality.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-2">E-commerce Solutions</h3>
          <p className="text-slate-300">
            We develop robust e-commerce platforms that enable businesses to sell products and services online, driving revenue and growth.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-2">Enterprise Software Solutions</h3>
          <p className="text-slate-300">
            We provide scalable and secure enterprise software solutions to streamline business processes, enhance collaboration, and drive efficiency.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
