import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto py-8">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold mb-2 text-black-300">About LANO TECH</h2>
        <p className="text-lg text-slate-300">Empowering Businesses with Innovative Software Solutions</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Overview */}
        <div className="bg-gradient-to-r from-yellow-100 from-10% via-sky-300 via-30% to-emerald-100 to-50% rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-2 text-white-300">Overview</h3>
          <p className="text-green-300 mb-4">
            LANO TECH is a dynamic software development company based in Athi River, Kenya. We specialize in crafting innovative software solutions tailored to meet the unique needs of our clients across various industries. With a commitment to excellence and a passion for technology, we strive to deliver cutting-edge software products that empower businesses to thrive in the digital age.
          </p>
        </div>
        {/* Mission */}
        <div className="bg-gradient-to-r from-yellow-100 from-10% via-sky-300 via-30% to-emerald-100 to-50% rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-2 text-green-200">Mission</h3>
          <p className="text-green-300 mb-4">
            At LANO TECH, our mission is to leverage the power of technology to drive business growth and transformation. We are dedicated to delivering superior software solutions that enhance efficiency, productivity, and competitiveness for our clients.
          </p>
        </div>
        {/* Vision */}
        <div className="bg-gradient-to-r from-green-100 rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-2 text-slate-300">Vision</h3>
          <p className="text-slate-300 mb-4">
            Our vision is to be a leading provider of innovative software solutions, recognized for our commitment to quality, creativity, and customer satisfaction. We aim to continually push the boundaries of possibility in software development, contributing to the advancement of technology and the success of our clients.
          </p>
        </div>
        {/* Core Values */}
        <div className="bg-gradient-to-r from-green-100 rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-2 text-slate-300">Core Values</h3>
          <ul className="list-disc list-inside text-slate-300 mb-4">
            <li>Innovation: We embrace creativity and innovation, constantly seeking new ways to solve complex challenges and exceed expectations.</li>
            <li>Quality: We uphold the highest standards of quality in everything we do, from software development to customer service.</li>
            <li>Integrity: We conduct our business with honesty, transparency, and integrity, fostering trust and reliability in all our relationships.</li>
            <li>Collaboration: We believe in the power of collaboration and teamwork, working closely with our clients to understand their needs and deliver tailored solutions.</li>
            <li>Continuous Improvement: We are committed to continuous learning and improvement, staying ahead of the curve in technology trends and best practices.</li>
          </ul>
        </div>
        {/* Services */}
        <div className="bg-gradient-to-r from-green-100 rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-2 text-blue-500">Services</h3>
          <p className="text-cyan-300 mb-4">
            LANO TECH offers a comprehensive range of software development services, including:
          </p>
          <ul className="list-disc list-inside text-white mb-4">
            <li>Custom Software Development</li>
            <li>Web Development</li>
            <li>Mobile App Development</li>
            <li>E-commerce Solutions</li>
            <li>Enterprise Software Solutions</li>
          </ul>
        </div>

      </div>
      <div className="text-center mt-8">
        <h3 className="text-xl font-semibold mb-2 text-blue-500">Contact Us</h3>
        <p className="text-slate-300 mb-4">
          Ready to take your business to the next level with innovative software solutions? Get in touch with LANO TECH today to discuss your project requirements.
        </p>
        <p className="text-slate-300 mb-4">
          Address: P. O. BOX 423-00204 Athi River,<br />
          Phone: +254 711829140<br />
          Email: lanotech254@gmail.com<br />
          Website: <a href="http://www.somocloud.org" className="text-blue-500 hover:text-blue-800">www.somocloud.org</a>
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
